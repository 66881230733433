<template>
  <div>
    <a-spin
      :tip="tip"
      size="large"
      :spinning="spinning"
    >
      <a-card :bordered="false">
        <a-steps :current="currentStep">
          <a-step
            title="步骤一"
            description="上传客户检测报告文件"
          >
          </a-step>
          <a-step
            title="步骤二"
            description="核对系统识别结果"
          >
          </a-step>
          <a-step
            title="步骤三"
            description="确认保存检测结果数据"
          >
          </a-step>
        </a-steps>

        <a-divider style="margin-top: 16px;" />

        <div>
          <template v-if="currentStep === 0">
            <a-upload
              name="file"
              :multiple="true"
              action="/auth/UploadFiles/upload"
              method="post"
              :headers="{Authorization: 'Bearer '+this.$store.state.user.token}"
              :data="{ localPath: 'aiReadFile',storageMark:'local' }"
              :beforeUpload="beforeUpload"
              :file-list="fileList"
              :remove="rmUpImgFile"
              @change="upImgChange"
            >
              <a-button
                type="primary"
                icon="upload"
                class="mb-10"
              >上传报告文件</a-button>
            </a-upload>
          </template>

          <template v-if="currentStep >= 1">
            <div v-if="aiResultArr.length > 0">
              <div
                v-for="(iav,aii) in aiResultArr"
                :key="aii"
              >
                <a-divider v-if="aii > 0" />
                <!-- iav.fn.replace('public/aiReadFile/','') -->
                <h6>文件：{{ iav['文件名'] }}，客户姓名：{{ iav['客户姓名'] }}，检测时间：{{ iav['检测时间'] }}，检测项目：<span
                    class="font-error"
                    v-if="iav['检测数据'].length < 1"
                  >AI未识别出检测数据</span>
                </h6>
                <a-table
                  :columns="aiTableColumns"
                  :data-source="iav['检测数据']"
                  rowKey="检测项目"
                  bordered
                  size="small"
                  :pagination="false"
                >
                  <a-form-item
                    slot="item_def_id"
                    slot-scope="text,record,iidx"
                    :label="false"
                    style="margin-bottom:0;"
                  >
                    <a-select
                      :allowClear="true"
                      style="width:100%;"
                      size="small"
                      show-search
                      option-filter-prop="children"
                      :value="text"
                      placeholder="请选择与系统匹配的检测项目"
                      @change="(e)=>changeMatcheitem(e,aii,iidx)"
                    >
                      <a-select-option
                        v-for="defInfo in optionsArr"
                        :key="defInfo.id"
                        :value="defInfo.id"
                        :title="'【'+defInfo.def_name+'】'+defInfo.name"
                      >
                        {{ '【'+defInfo.def_name+'】'+defInfo.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <span
                    slot="action"
                    slot-scope="text, record,iidx"
                  >
                    <template>
                      <a-popconfirm
                        title="确定将当前数据删除？"
                        ok-text="确认"
                        cancel-text="取消"
                        @confirm="clickDelRes(record,aii,iidx)"
                      >
                        <a class="font-error">删除</a>
                      </a-popconfirm>
                    </template>
                  </span>
                </a-table>
              </div>
            </div>
            <a-empty
              v-else
              description="暂无识别结果"
            />
          </template>

          <a-row>
            <!-- :offset="currentStep === 1 ? 9 : 10" -->
            <a-col
              :span="24"
              class="mt-20 text-center"
            >
              <!--  -->
              <a-button
                v-if="fileList.length > 0 && currentStep === 0"
                @click="aiReadClick"
                type="primary"
                icon="android"
              >确认识别文件</a-button>
              <a-button
                v-if="fileList.length > 0 && currentStep === 1 && aiResultArr.length > 0"
                @click="aiSaveClick"
                type="primary"
                icon="check"
                :class="currentStep === 1 ? 'mr-10' : ''"
              >确认保存</a-button>
              <a-button
                v-if="fileList.length > 0 && currentStep >= 1"
                @click="aiClearClick"
                type="default"
                icon="delete"
              >清空数据重新上传</a-button>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { getTestItemDefListArr } from '@/api/dms/testItem'
import { savePatientMeasureInfo, aiReadFileData, getAiConversationData } from '@/api/dms/patient'
import { rmFile } from '@/api/manage'
import moment from 'moment'

export default {
  name: 'AddPatientMeasureInfo',
  data () {
    return {
      spinning: false,
      tip: '加载中...',
      optionsArr: [],
      fileList: [],
      currentStep: 0,
      upNum: 0,
      doneNum: 0,
      aiResultArr: [],
      aiTableColumns: [
        {
          title: '序号',
          width: 65,
          dataIndex: 'id',
          align: 'center',
          customRender: (text, row, key) => {
            return key + 1
          }
        },
        {
          title: '报告原始数据',
          children: [
            {
              title: '检测项目',
              // width: 180,
              ellipsis: true,
              dataIndex: '检测项目'
            },
            {
              title: '检测结果',
              ellipsis: true,
              width: 120,
              dataIndex: '检测结果值'
            },
            {
              title: '单位',
              ellipsis: true,
              width: 120,
              dataIndex: '单位'
            },
          ]
        },
        {
          title: '系统匹配项目',
          ellipsis: true,
          dataIndex: 'item_def_id',
          scopedSlots: { customRender: 'item_def_id' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 60,
          scopedSlots: { customRender: 'action' }
        }
      ],
      aiChatId: false,
      chatSec: 0,
      itmer: null
    }
  },
  created () {
    // 检测参数
    this.params = JSON.parse(sessionStorage.getItem('HisPatients.addPatientMeasureInfo'))
    if (this.params === null || this.params.patient === undefined || this.params.patient < 1) {
      this.$message.error('请确认选择客户！')
      return this.$router.go(-1)
    }
    this.spinning = true
    // 获取数据 his.result_type
    getTestItemDefListArr({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then((res) => {
      // console.log(res)
      if (res.status !== undefined && res.status === 1 && res.data !== undefined) {
        this.optionsArr = res.data
      }
      this.spinning = false
    })
  },
  methods: {
    moment,
    beforeUpload (file) {
      const maxSize = 1024 * 1024 * 10
      if (file.size > maxSize) {
        this.$message.error('请确认上传文件不超过10MB！')
        return false
      }
      if (this.fileList.length > 0 && this.aiResultArr.length > 0) {
        this.$message.error('请先清空已上传的文件再重新开始！')
        return false
      }
      this.spinning = true
      this.upNum++
    },
    upImgChange (info) {
      // 上传完成后的事件监听
      if (info.file.status === 'done') {
        const upRes = info.file.response
        if (upRes.status === undefined || upRes.status !== 1) {
          info.fileList.forEach((oneFile) => {
            if (oneFile.uid === info.file.uid) {
              oneFile.status = 'error'
            }
          })
          const showMsg = upRes.msg || '上传失败！请刷新后重试！'
          this.$message.error(showMsg)
        } else {
          this.$message.success('上传成功！')
        }
        this.doneNum++
      }

      if (info.file.status !== undefined) {
        this.fileList = info.fileList

        if (this.upNum === this.doneNum) {
          this.spinning = false
        }
      }
    },
    rmUpImgFile (file) {
      // file.name
      if (file.status !== 'done') {
        this.$message.success('删除完成！')
        return true
      }
      // console.log(file)

      this.spinning = true
      rmFile({ fileName: file.response.data }).then((res) => {
        this.spinning = false
        if (res.status !== undefined && res.status === 1) {
          this.$message.success('删除成功！')
        } else {
          this.$message.error(res.msg || '删除失败！请刷新后重试！')
        }
        return true
      }).catch(() => {
        this.spinning = false
        this.$message.error('删除出错！请刷新后重试！')
        return false
      })
    },
    saveRequest (data, formObj) {
      this.spinning = true
      this.tip = '处理中...'
      const that = this
      savePatientMeasureInfo(data).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '检测指标数据保存成功！',
            content: false,
            cancelText: '继续录入',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              return that.$router.go(-1)
            },
            onCancel () {

            }
          })
        } else {
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
        this.spinning = false
      })
    },
    aiReadClick () {
      if (this.fileList.length < 1) {
        this.$message.error('请确认报告文件上传成功！')
        return false
      }
      if (this.aiChatId !== false) {
        this.getAiResData()
      } else {
        const filesData = []
        // console.log(this.fileList)

        this.fileList.map(fv => {
          if (fv.response !== undefined && fv.response.data !== undefined && fv.response.data !== null) {
            filesData.push({ name: fv.name, path: fv.response.data })
          }
        })
        this.spinning = true
        this.tip = '文件处理中...'
        // const that = this
        aiReadFileData({ enterprise: this.$store.getters.userInfo.user.target_enterprise, fileNames: filesData, patient: this.params.patient }).then((res) => {
          console.log(res)
          if (res.status !== undefined && res.status === 1) {
            // this.aiResultArr = res.data
            // this.currentStep = 1
            this.aiChatId = res.data.chat
            this.tip = 'AI处理中...'
            const ttimer = setTimeout(() => {
              this.getAiResData(true)
              clearTimeout(ttimer)
            }, 10000)
          } else {
            this.$message.error(res.msg || '操作失败！请刷新后重试！')
            this.spinning = false
          }
        })
      }
    },
    getAiResData (timerIni = false) {
      this.spinning = true
      if (timerIni) {
        this.itmer = setInterval(() => {
          this.chatSec++
          this.tip = 'AI识别中(' + this.chatSec + 's)...'
        }, 1000)
      }
      // const that = this
      getAiConversationData({ enterprise: this.$store.getters.userInfo.user.target_enterprise, chat: this.aiChatId }).then((res) => {
        console.log(res)
        if (res.status !== undefined && res.status === 1) {
          this.aiResultArr = res.data
          this.currentStep = 1
          this.chatSec = 0
          this.spinning = false
          clearInterval(this.itmer)
          this.$message.success('文件识别完成！')
        } else if (res.status !== undefined && res.status === 0) {
          // 对话未完成等待5秒后重新再来
          const ttimer = setTimeout(() => {
            this.getAiResData()
            clearTimeout(ttimer)
          }, 15000)
        } else {
          clearInterval(this.itmer)
          this.$message.error(res.msg || 'AI读取失败！请刷新后重试！', 10)
          this.spinning = false
          this.aiClearClick()
        }
        // this.spinning = false
      }).catch((e) => {
        this.$message.error(e.message || e.msg || 'AI读取失败！请刷新后重试！', 10)
        this.spinning = false
        this.aiClearClick()
      })
    },
    clickDelRes (row, i, ii) {
      // console.log(row, idx)
      if (this.aiResultArr[i].resDt['检测数据'][ii] === undefined) {
        this.$message.error('请确认要删除的项目存在！')
      }
      this.aiResultArr[i].resDt['检测数据'].splice(ii, 1)
    },
    changeMatcheitem (v, i, ii) {
      // console.log(v, i, ii)
      this.aiResultArr[i].resDt['检测数据'][ii].item_def_id = v
      this.aiResultArr = [...this.aiResultArr]
    },
    aiSaveClick () {
      this.$message.success('保存完成！')
      this.currentStep = 2
    },
    aiClearClick () {
      this.aiResultArr = []
      this.fileList = []
      this.upNum = 0
      this.doneNum = 0
      this.currentStep = 0
      this.aiChatId = false
      this.chatSec = 0
    }
  }
}

</script>

<style lang="scss" scoped>
.tab-div-box {
  width: calc(100% - 40px);
}
.page-icon-left {
  line-height: 40px;
  vertical-align: top;
}
</style>