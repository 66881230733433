<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        :bodyStyle="showChoose ? {padding: 0, paddingTop: '16px'} : {}"
      >
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="6">
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="所属分类">
                    <!-- <a-select
                      v-model="queryParam.category"
                      placeholder="全部"
                      :options="foodCompCategoryListData"
                      :allowClear="true"
                    >
                    </a-select> -->
                    <a-tree-select
                      v-model="queryParam.category"
                      style="width: 100%"
                      :tree-data="foodCompCategoryListData"
                      tree-checkable
                      placeholder="全部"
                      :maxTagCount="2"
                      :allowClear="true"
                    />
                  </a-form-item>
                </a-col>
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="食物名称"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="4"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.foodCompositionListTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      style="margin-left: 8px"
                      icon="reload"
                      @click="() => (this.queryParam = {  })"
                    >重置</a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="foodCompositionListTable"
          :size="tableSize"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <a @click="clickAdd(record)">添加</a>
            </template>
          </span>
        </s-table>

        <template v-if="showChoose">
          <h6>已选择食物</h6>
          <a-divider style="margin-top: 0;" />

          <a-table
            :columns="chooseColumns"
            :data-source="chooseData"
            :size="tableSize"
            :pagination="false"
            rowKey="id"
          >
            <div
              slot="food_name"
              slot-scope="text,record"
              class="cursor-pointer"
            >
              <sup
                title="当前新添加食物，待保存！"
                class="font-error"
                v-if="record.id <= 0"
              >new </sup>
              {{ text }}
            </div>
            <div
              slot="intake"
              slot-scope="text,record,idx"
            >
              <a-input
                style="width:100%;"
                addon-after="克"
                size="small"
                :value="text"
                type="number"
                :step="0.01"
                :min="0"
                :max="99999"
                @change="(e)=>inputChangeVal((e.target.value > 99999 ? 99999: (e.target.value < 0 ? 0 : e.target.value)),idx,'intake')"
              />
            </div>
            <span
              slot="action"
              slot-scope="text, record,idx"
            >
              <template>
                <a
                  @click="clickRemoveRow(idx)"
                  class="font-error"
                >删除</a>
              </template>
            </span>
          </a-table>
        </template>
      </a-card>
    </a-spin>
  </div>
</template>

<script>
import { selectListFoodCompositionCategory, listFoodComposition } from '@/api/dms/foodComposition'
import { saveFoodRecommend, getPatientFoodRecommendList, removeFoodRecommend } from '@/api/dms/foodRecommend'

export default {
  name: 'FoodCompositionList',
  data () {
    return {
      spinning: false,
      foodCompCategoryListData: [],
      columns: [
        {
          title: 'ID',
          width: 55,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '分类名称',
          width: 100,
          dataIndex: 'category_name',
          ellipsis: false
        },
        {
          title: '食物名称',
          width: 150,
          dataIndex: 'food_name',
          ellipsis: false
        },
        {
          title: '可食部分(%)',
          width: 90,
          ellipsis: false,
          dataIndex: 'edible'
        },
        {
          title: '水分',
          ellipsis: false,
          dataIndex: 'water',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '能量(kCal)',
          width: 75,
          ellipsis: false,
          dataIndex: 'energy_kcal'
        },
        {
          title: '蛋白质',
          ellipsis: false,
          width: 60,
          dataIndex: 'protein',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '脂肪',
          ellipsis: false,
          dataIndex: 'fat',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '胆固醇',
          ellipsis: false,
          width: 60,
          dataIndex: 'cholesterol',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '灰分',
          ellipsis: false,
          dataIndex: 'ash',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '碳水化物',
          ellipsis: false,
          width: 80,
          dataIndex: 'carbohydrates',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '膳食纤维',
          ellipsis: false,
          width: 80,
          dataIndex: 'dietary_fiber',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '胡萝卜素',
          ellipsis: false,
          dataIndex: 'carotene',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '维生素A',
          ellipsis: false,
          width: 60,
          dataIndex: 'va',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: 'α-TE',
          ellipsis: false,
          dataIndex: 'ate',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        // {
        //   title: '视黄醇当量',
        //   ellipsis: false,
        //   dataIndex: 're',
        //   customRender: (text) => text !== null && text !== '' ? text : ''
        // },
        {
          title: '硫胺素(VB1)',
          ellipsis: false,
          width: 90,
          dataIndex: 'vb1',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '核黄素(VB2)',
          ellipsis: false,
          width: 90,
          dataIndex: 'vb2',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '烟酸(VPP)',
          ellipsis: false,
          width: 80,
          dataIndex: 'vpp',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '维生素C',
          ellipsis: false,
          width: 60,
          dataIndex: 'vc',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        // {
        //   title: '维生素E',
        //   ellipsis: false,
        //   dataIndex: 've',
        //   customRender: (text) => text !== null && text !== '' ? text : ''
        // },
        {
          title: '钙',
          ellipsis: false,
          dataIndex: 'cal',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '磷',
          ellipsis: false,
          dataIndex: 'p',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '钾',
          ellipsis: false,
          dataIndex: 'k',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '钠',
          ellipsis: false,
          dataIndex: 'na',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '镁',
          ellipsis: false,
          dataIndex: 'mg',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '铁',
          ellipsis: false,
          dataIndex: 'fe',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '锌',
          ellipsis: false,
          dataIndex: 'zn',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '硒',
          ellipsis: false,
          dataIndex: 'se',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '铜',
          ellipsis: false,
          dataIndex: 'cu',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '锰',
          ellipsis: false,
          dataIndex: 'mn',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '碘',
          ellipsis: false,
          dataIndex: 'i',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '饱和脂肪酸',
          ellipsis: false,
          width: 90,
          dataIndex: 'sfa',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '单不饱和脂肪酸',
          ellipsis: false,
          width: 100,
          dataIndex: 'mufa',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '多不饱和脂肪酸',
          ellipsis: false,
          width: 100,
          dataIndex: 'pufa',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        {
          title: '脂肪酸合计',
          ellipsis: false,
          width: 90,
          dataIndex: 'totala',
          customRender: (text) => text !== null && text !== '' ? text : ''
        },
        // {
        //   title: '操作',
        //   ellipsis: true,
        //   width: 50,
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      tableScroll: { x: true },
      pageSize: 10,
      // 查询参数
      queryParam: {},
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['category'] }, parameter, this.queryParam)
        return listFoodComposition(requestParameters)
          .then(res => {
            console.log(res.data)
            return res
          })
      },
      chooseColumns: [
        {
          title: '序号',
          width: 55,
          dataIndex: 'id',
          ellipsis: true,
          customRender: (text, row, key) => {
            return row.id > 0 ? (key + 1) : '-'
          }
        },
        {
          title: '分类名称',
          width: 100,
          dataIndex: 'category_name',
          ellipsis: true
        },
        {
          title: '食物名称',
          width: 250,
          dataIndex: 'food_name',
          ellipsis: false,
          scopedSlots: { customRender: 'food_name' }
        },
        {
          title: '能量(kCal)',
          width: 100,
          ellipsis: true,
          dataIndex: 'energy_kcal'
        },
        {
          title: '推荐摄入量',
          ellipsis: true,
          dataIndex: 'intake',
          scopedSlots: { customRender: 'intake' }
        },
        {
          title: '操作',
          ellipsis: true,
          dataIndex: 'action',
          width: 75,
          scopedSlots: { customRender: 'action' }
        }
      ],
      chooseData: []
    }
  },
  props: {
    parentParams: {
      type: Object,
      default: null
    },
    tableSize: {
      type: String,
      default: 'default'
    },
    showChoose: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    statusTypeFilter (type) {
      if (type <= 0) {
        return 'error'
      } else if (type === 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  created () {
    this.spinning = true
    selectListFoodCompositionCategory({ enterprise: this.$store.getters.userInfo.user.target_enterprise }).then(res => {
      if (res.status !== undefined && res.status === 1) {
        this.foodCompCategoryListData = res.data
      }
      this.spinning = false
    })
    // 如果是父组件传值要回显已选数据
    if (this.parentParams !== null && this.parentParams.patient !== undefined && this.showChoose) {
      // pageSize为1000 是因为共用的列表分页查询接口，要是后期要调整的话考虑重新换一个新的不分页查询接口
      this.loadChooseOriData()
    }
  },
  methods: {
    loadChooseOriData () {
      getPatientFoodRecommendList({ enterprise: this.$store.getters.userInfo.user.target_enterprise, patient: this.parentParams.patient, page: 1, pageSize: 1000 }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          this.chooseData = res.data
        }
        this.spinning = false
      })
    },
    clickAdd (record) {
      const recordObj = JSON.parse(JSON.stringify(record))
      recordObj.id = -recordObj.id
      const cdFilter = this.chooseData.filter((cv) => {
        return cv.id === recordObj.id || cv.food_name === recordObj.food_name
      })
      if (cdFilter.length > 0) {
        this.$message.error('该食物已选择！')
        return false
      } else {
        recordObj.intake = 100
        this.chooseData.push(recordObj)
        return false
      }
    },
    inputChangeVal (e, idx, field) {//选中的列表中摄入量的change事件
      this.chooseData[idx][field] = e
      this.chooseData = [...this.chooseData]
    },
    clickRemoveRow (idx) { // 列表中删除一行的事件
      if (this.chooseData[idx].id > 0) {
        this.spinning = true
        removeFoodRecommend({ enterprise: this.$store.getters.userInfo.user.target_enterprise, patient: this.parentParams.patient, foodRecommend: this.chooseData[idx].id }).then((res) => {
          this.spinning = false
          if (res.status !== undefined && res.status === 1) {
            this.chooseData.splice(idx, 1)
          } else {
            this.$message.error(res.msg || '保存失败！请刷新后重试！')
            return false
          }
        })
      } else {
        this.chooseData.splice(idx, 1)
      }
    },
    saveFoodCompositionClick () {
      if (this.chooseData.length < 1) {
        this.$message.error('该确认已选择食物！')
        return false
      }
      const cdFilterArr = this.chooseData.filter((cv) => {
        return cv.intake === undefined || cv.intake === null || cv.intake === '' || isNaN(cv.intake) || cv.intake <= 0
      })
      if (cdFilterArr.length > 0) {
        this.$message.error('该确认已选择食物摄入量大于0！')
        return false
      }
      this.spinning = true
      const that = this
      saveFoodRecommend({ enterprise: this.$store.getters.userInfo.user.target_enterprise, patient: this.parentParams.patient, foods: this.chooseData }).then((res) => {
        if (res.status !== undefined && res.status === 1) {
          that.$confirm({
            title: '保存成功！',
            content: false,
            cancelText: '留在当前页面',
            okText: '返回管理列表',
            icon: () => {
              return that.$createElement('a-icon', {
                props: { type: 'check-circle' },
                style: { color: '#52c41a' }
              })
            },
            onOk () {
              this.spinning = false
              return that.$router.go(-1)
            },
            onCancel () {
              // 回显
              that.loadChooseOriData()
              return false
            }
          })
        } else {
          this.spinning = false
          this.$message.error(res.msg || '保存失败！请刷新后重试！')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.ant-table {
  td {
    white-space: nowrap;
  }
  th {
    white-space: nowrap;
  }
}
</style>
