<template>
  <div>
    <a-modal
      v-model="foodRecipeDetailModalVisibleCu"
      title="食谱详情"
      :width="1280"
      :maskClosable="false"
      @cancel="handleOkView"
    >
      <template slot="footer">
        <a-button
          class="mr-5"
          icon="close"
          @click="handleOkView"
        >
          关闭
        </a-button>
      </template>

      <a-descriptions
        :column="5"
        bordered
        size="small"
      >
        <a-descriptions-item
          :span="2"
          label="食谱名称"
        >
          {{recipeDetail.recipe_name}}
        </a-descriptions-item>
        <a-descriptions-item label="显示名称">
          {{recipeDetail.recipe_name_show}}
        </a-descriptions-item>
        <a-descriptions-item label="食用数量">
          {{recipeDetail.eating_count + recipeDetail.eating_unit}}
        </a-descriptions-item>
        <a-descriptions-item label="适合人数">
          {{recipeDetail.person}}
        </a-descriptions-item>

        <a-descriptions-item :label="'热量('+recipeDetail.calory_unit+')'">
          {{recipeDetail.calory}}
        </a-descriptions-item>
        <a-descriptions-item :label="'热量('+recipeDetail.joule_unit+')'">
          {{recipeDetail.joule}}
        </a-descriptions-item>
        <a-descriptions-item :label="'蛋白质(' + recipeDetail.protein_unit+')'">
          {{recipeDetail.protein}}
        </a-descriptions-item>
        <a-descriptions-item :label="'脂肪(' + recipeDetail.fat_unit+')'">
          {{recipeDetail.fat}}
        </a-descriptions-item>
        <a-descriptions-item :label="'碳水化合物(' + recipeDetail.carbohydrate_unit+')'">
          {{recipeDetail.carbohydrate}}
        </a-descriptions-item>

        <a-descriptions-item :label="'饱和脂肪(' + recipeDetail.saturated_fat_unit+')'">
          {{recipeDetail.saturated_fat}}
        </a-descriptions-item>
        <a-descriptions-item :label="'反式脂肪(' + recipeDetail.fatty_acid_unit+')'">
          {{recipeDetail.fatty_acid}}
        </a-descriptions-item>
        <a-descriptions-item :label="'单不饱和脂肪(' + recipeDetail.mufa_unit+')'">
          {{recipeDetail.mufa}}
        </a-descriptions-item>
        <a-descriptions-item :label="'多不饱和脂肪(' + recipeDetail.pufa_unit+')'">
          {{recipeDetail.pufa}}
        </a-descriptions-item>
        <a-descriptions-item :label="'胆固醇(' + recipeDetail.cholesterol_unit+')'">
          {{recipeDetail.cholesterol}}
        </a-descriptions-item>

        <a-descriptions-item :label="'糖(' + recipeDetail.sugar_unit+')'">
          {{recipeDetail.sugar}}
        </a-descriptions-item>
        <a-descriptions-item :label="'膳食纤维(' + recipeDetail.fiber_dietary_unit+')'">
          {{recipeDetail.fiber_dietary}}
        </a-descriptions-item>
        <a-descriptions-item :label="'钠(' + recipeDetail.natrium_unit+')'">
          {{recipeDetail.natrium}}
        </a-descriptions-item>
        <a-descriptions-item :label="'酒精(' + recipeDetail.alcohol_unit+')'">
          {{recipeDetail.alcohol}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素A(' + recipeDetail.vitamin_a_unit+')'">
          {{recipeDetail.vitamin_a}}
        </a-descriptions-item>

        <a-descriptions-item :label="'胡萝卜素(' + recipeDetail.carotene_unit+')'">
          {{recipeDetail.carotene}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素D(' + recipeDetail.vitamin_d_unit+')'">
          {{recipeDetail.vitamin_d}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素E(' + recipeDetail.vitamin_e_unit+')'">
          {{recipeDetail.vitamin_e}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素K(' + recipeDetail.vitamin_k_unit+')'">
          {{recipeDetail.vitamin_k}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素B1(' + recipeDetail.thiamine_unit+')'">
          {{recipeDetail.thiamine}}
        </a-descriptions-item>

        <a-descriptions-item :label="'维生素B2(' + recipeDetail.lactoflavin_unit+')'">
          {{recipeDetail.lactoflavin}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素B6(' + recipeDetail.vitamin_b6_unit+')'">
          {{recipeDetail.vitamin_b6}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素B12(' + recipeDetail.vitamin_b12_unit+')'">
          {{recipeDetail.vitamin_b12}}
        </a-descriptions-item>
        <a-descriptions-item :label="'维生素C(' + recipeDetail.vitamin_c_unit+')'">
          {{recipeDetail.vitamin_c}}
        </a-descriptions-item>
        <a-descriptions-item :label="'烟酸(' + recipeDetail.niacin_unit+')'">
          {{recipeDetail.niacin}}
        </a-descriptions-item>

        <a-descriptions-item :label="'叶酸(' + recipeDetail.folacin_unit+')'">
          {{recipeDetail.folacin}}
        </a-descriptions-item>
        <a-descriptions-item :label="'泛酸(' + recipeDetail.pantothenic_unit+')'">
          {{recipeDetail.pantothenic}}
        </a-descriptions-item>
        <a-descriptions-item :label="'生物素(' + recipeDetail.biotin_unit+')'">
          {{recipeDetail.biotin}}
        </a-descriptions-item>
        <a-descriptions-item :label="'胆碱(' + recipeDetail.choline_unit+')'">
          {{recipeDetail.choline}}
        </a-descriptions-item>
        <a-descriptions-item :label="'磷(' + recipeDetail.phosphor_unit+')'">
          {{recipeDetail.phosphor}}
        </a-descriptions-item>

        <a-descriptions-item :label="'钾(' + recipeDetail.kalium_unit+')'">
          {{recipeDetail.kalium}}
        </a-descriptions-item>
        <a-descriptions-item :label="'镁(' + recipeDetail.magnesium_unit+')'">
          {{recipeDetail.magnesium}}
        </a-descriptions-item>
        <a-descriptions-item :label="'钙(' + recipeDetail.calcium_unit+')'">
          {{recipeDetail.calcium}}
        </a-descriptions-item>
        <a-descriptions-item :label="'铁(' + recipeDetail.iron_unit+')'">
          {{recipeDetail.iron}}
        </a-descriptions-item>
        <a-descriptions-item :label="'锌(' + recipeDetail.zinc_unit+')'">
          {{recipeDetail.zinc}}
        </a-descriptions-item>

        <a-descriptions-item :label="'碘(' + recipeDetail.iodine_unit+')'">
          {{recipeDetail.iodine}}
        </a-descriptions-item>
        <a-descriptions-item :label="'硒(' + recipeDetail.selenium_unit+')'">
          {{recipeDetail.selenium}}
        </a-descriptions-item>
        <a-descriptions-item :label="'铜(' + recipeDetail.copper_unit+')'">
          {{recipeDetail.copper}}
        </a-descriptions-item>
        <a-descriptions-item :label="'氟(' + recipeDetail.fluorine_unit+')'">
          {{recipeDetail.fluorine}}
        </a-descriptions-item>
        <a-descriptions-item :label="'锰(' + recipeDetail.manganese_unit+')'">
          {{recipeDetail.manganese}}
        </a-descriptions-item>

        <a-descriptions-item
          label="主料"
          :span="5"
        >
          <!-- <ul style="display:flex;list-style:none;"> -->
          <a-tag
            v-for="(item,idx) in recipeDetail.major_materials_j"
            :key="idx"
          >
            {{item.food_name}} ：{{item.weight}} 克
          </a-tag>
          <!-- </ul> -->
        </a-descriptions-item>

        <a-descriptions-item
          label="辅料"
          :span="5"
        >
          <a-tag
            v-for="(item,idx) in recipeDetail.minor_materials_j"
            :key="idx"
          >
            {{item.name}} ：{{item.weight}} 克
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item
          label="配料"
          :span="5"
        >
          <a-tag
            v-for="(item,idx) in recipeDetail.seasoning_j"
            :key="idx"
          >
            {{item.name}} ：{{item.weight}} 克
          </a-tag>
        </a-descriptions-item>

        <a-descriptions-item
          label="标签"
          :span="5"
        >
          <a-tag
            color="green"
            v-for="(item,idx) in recipeDetail.recipe_tag"
            :key="idx"
          >
            {{ item.tag_text }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item
          label="描述说明"
          :span="5"
        >
          {{ recipeDetail.description }}
        </a-descriptions-item>

      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'FoodRecipeDetailModal',
  data () {
    return {
      foodRecipeDetailModalVisibleCu: false
    }
  },
  props: {
    foodRecipeDetailModalVisible: {
      type: Boolean,
      default: false
    },
    recipeDetail: {
      type: Object,
      default: {}
    }
  },
  watch: {
    foodRecipeDetailModalVisible: {
      handler (newVal, oldVal) {
        this.foodRecipeDetailModalVisibleCu = this.foodRecipeDetailModalVisible
      },
      deep: true // 开启深度监听 只要对象中任何一个属性变化 都会触发'对象的侦听器'
    }
  },
  created () {

  },
  methods: {
    handleOkView () {
      this.dietaryPatternDrawerVisibleCu = false
      this.$emit('foodRecipeDetailModalVisibleChange', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
