<template>
  <div>
    <a-spin
      tip="处理中..."
      size="large"
      :spinning="spinning"
    >
      <a-card
        :bordered="false"
        :bodyStyle="sourceMark === 'choose' ? {padding: 0, paddingTop: '16px'} : {}"
      >
        <div class="mx-25">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="6">
                <a-col
                  :md="4"
                  :sm="24"
                >
                  <a-form-item label="所属">
                    <a-select
                      v-model="queryParam.owner_type"
                      :allowClear="true"
                      placeholder="全部"
                    >
                      <a-select-option value="0">
                        公开
                      </a-select-option> <a-select-option value="10">
                        私有
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col
                  :md="8"
                  :sm="24"
                >
                  <a-form-item label="搜索">
                    <a-input
                      v-model="queryParam.search"
                      placeholder="菜谱名称"
                    />
                  </a-form-item>
                </a-col>

                <a-col
                  :md="4"
                  :sm="24"
                >
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      icon="search"
                      @click="$refs.foodRecipeDefListTable.refresh(true)"
                    >查询</a-button>
                    <a-button
                      style="margin-left: 8px"
                      icon="reload"
                      @click="() => (this.queryParam = { owner_type: '0' })"
                    >重置</a-button>

                    <router-link
                      :to="'/FoodRecipes/addFoodRecipe'"
                      v-if="sourceMark===null"
                    >
                      <a-button
                        style="margin-left: 8px"
                        type="danger"
                        icon="plus"
                      >新增</a-button>
                    </router-link>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <s-table
          ref="foodRecipeDefListTable"
          :size="tableSize"
          rowKey="id"
          showPagination="auto"
          :columns="columns"
          :data="loadData"
          :pageSize="pageSize"
          :scroll="tableScroll"
        >

          <div
            slot="cover"
            slot-scope="text, record"
            style="max-width:25px;height:25px;cursor:pointer;"
          >
            <img
              v-if="record.cover_show !== undefined && record.cover_show !== ''"
              title="点击查看原图"
              style="width: 100%"
              :src="record.cover_show"
              @click="handlePreview(record.cover_show)"
            />
          </div>

          <span
            slot="recipe_tag"
            slot-scope="text,record"
          >
            <a-tag
              color="green"
              v-for="(item,idx) in record.recipe_tag"
              :key="idx"
            >
              {{ item.tag_text }}
            </a-tag>
          </span>

          <span
            slot="action"
            slot-scope="text, record"
          >
            <template>
              <a @click="clickView(record)">详情</a>

              <a-divider type="vertical" />

              <a
                v-if="sourceMark === 'choose'"
                @click="clickAddRecipe(record)"
              >添加</a>
              <a
                v-if="sourceMark === null"
                @click="clickEdit(record)"
              >编辑</a>

            </template>
          </span>
        </s-table>
      </a-card>
    </a-spin>

    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="货品主图"
        style="width: 100%"
        :src="previewImg"
      />
    </a-modal>

    <!-- 详情弹窗组件 -->
    <food-recipe-detail-modal
      :recipeDetail="recipeDetail"
      :foodRecipeDetailModalVisible="foodRecipeDetailModalVisible"
      @foodRecipeDetailModalVisibleChange="foodRecipeDetailModalVisibleChange"
    />
  </div>
</template>

<script>
import { listFoodRecipeDef } from '@/api/dms/foodRecipe'
import FoodRecipeDetailModal from './FoodRecipeDetailModal'

export default {
  name: 'FoodRecipeList',
  components: { FoodRecipeDetailModal },
  data () {
    return {
      spinning: false,
      defaultColumns: [
        {
          title: 'ID',
          width: 55,
          dataIndex: 'id',
          ellipsis: true
        },
        {
          title: '图片',
          ellipsis: true,
          width: 95,
          dataIndex: 'cover',
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '名称',
          width: 160,
          dataIndex: 'recipe_name',
          ellipsis: true
        },
        {
          title: '显示名称',
          width: 160,
          dataIndex: 'recipe_name_show',
          ellipsis: false
        },
        {
          title: '重量',
          width: 80,
          dataIndex: 'weight',
          ellipsis: false,
          customRender: (text) => text !== null && text !== '' ? text + '克' : ''
        },
        {
          title: '分量',
          width: 80,
          dataIndex: 'eating_count',
          ellipsis: false,
          customRender: (text, record) => text !== null && text !== '' ? text + record.eating_unit : ''
        },
        {
          title: '适合人数',
          width: 80,
          dataIndex: 'person',
          ellipsis: false,
        },
        {
          title: '热量',
          width: 100,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 100,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 100,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 100,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '标签',
          ellipsis: true,
          width: 300,
          dataIndex: 'recipe_tag',
          scopedSlots: { customRender: 'recipe_tag' }
        },
        {
          title: '操作',
          ellipsis: true,
          width: 100,
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      chooseColumns: [
        {
          title: '图片',
          ellipsis: true,
          width: 65,
          dataIndex: 'cover',
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '名称',
          width: 160,
          dataIndex: 'recipe_name',
          ellipsis: false
        },
        {
          title: '重量',
          width: 80,
          dataIndex: 'weight',
          ellipsis: false,
          customRender: (text) => text !== null && text !== '' ? text + '克' : ''
        },
        // {
        //   title: '分量',
        //   width: 80,
        //   dataIndex: 'eating_count',
        //   ellipsis: false,
        //   customRender: (text, record) => text !== null && text !== '' ? text + record.eating_unit : ''
        // },
        // {
        //   title: '适合人数',
        //   width: 80,
        //   dataIndex: 'person',
        //   ellipsis: false,
        // },
        {
          title: '热量',
          width: 100,
          ellipsis: true,
          dataIndex: 'calory',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.calory_unit : ''
        },
        {
          title: '蛋白质',
          width: 80,
          ellipsis: true,
          dataIndex: 'protein',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.protein_unit : ''
        },
        {
          title: '脂肪',
          width: 80,
          ellipsis: true,
          dataIndex: 'fat',
          customRender: (text, record) => text !== null && text !== '' ? text + ' ' + record.fat_unit : ''
        },
        {
          title: '碳水化物',
          ellipsis: true,
          width: 80,
          dataIndex: 'carbohydrate',
          customRender: (text, record) => text !== null && text !== '' ? text + '' + record.carbohydrate_unit : ''
        },
        {
          title: '标签',
          ellipsis: true,
          width: 100,
          dataIndex: 'recipe_tag',
          scopedSlots: { customRender: 'recipe_tag' }
        },
        {
          title: '操作',
          ellipsis: true,
          width: 100,
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      columns: [],
      tableScroll: { x: 1500 },
      pageSize: 10,
      // 查询参数
      queryParam: { owner_type: "0" },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({ user: this.$store.getters.userInfo.user.id, enterprise: this.$store.getters.userInfo.user.target_enterprise, join: ['tags'] }, parameter, this.queryParam)
        return listFoodRecipeDef(requestParameters)
          .then(res => {
            console.log(res.data)
            return res
          })
      },
      recipeDetail: {},
      previewVisible: false,
      previewImg: '',
      foodRecipeDetailModalVisible: false,
    }
  },
  props: {
    tableSize: {
      type: String,
      default: 'default'
    },
    sourceMark: {
      type: String,
      default: null
    },
    arrIdx: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  filters: {
    statusTypeFilter (type) {
      if (type <= 0) {
        return 'error'
      } else if (type === 10) {
        return 'success'
      } else {
        return 'default'
      }
    }
  },
  created () {
    if (this.sourceMark === 'choose') {
      this.columns = this.chooseColumns
    } else {
      this.columns = this.defaultColumns
    }
  },
  methods: {
    clickView (record) {
      record.major_materials_j = record.major_materials === "" ? [] : JSON.parse(record.major_materials)
      record.minor_materials_j = record.minor_materials === "" ? [] : JSON.parse(record.minor_materials)
      record.seasoning_j = record.seasoning === "" ? [] : JSON.parse(record.seasoning)
      this.recipeDetail = record
      this.foodRecipeDetailModalVisible = true
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (img) {
      this.previewImg = img
      this.previewVisible = true
    },
    clickEdit (record) {
      sessionStorage.setItem('FoodRecipes.editFoodRecipe', JSON.stringify({ recipe: record.uid }))
      this.$router.push({ path: '/FoodRecipes/editFoodRecipe' })
    },
    clickAddRecipe (record) {
      this.$emit('sureChooseRecipe', { arrIdx: this.arrIdx, recipe: record })
    },
    foodRecipeDetailModalVisibleChange (v) {
      this.foodRecipeDetailModalVisible = v
      this.recipeDetail = {}
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
