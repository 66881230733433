import request from '@/utils/request'

const apiUrls = {
  getPatientInfo: '/HisPatients/getPatientInfo',
  listHisPatient: '/HisPatients/listHisPatient',
  addPatientInfo: '/HisPatients/addPatientInfo',
  updatePatientInfo: '/HisPatients/updatePatientInfo',
  getHisPatientSelectList: '/HisPatients/getHisPatientSelectList',
  listHisPlatformPatient: '/HisPatients/listHisPlatformPatient',
  // 客户关联的个人饮食习惯
  savePatientDietaryInfo: '/HealthPatientDietaryInfos/savePatientDietaryInfo',
  getPatientDietaryInfo: '/HealthPatientDietaryInfos/getPatientDietaryInfo',
  // 客户关联的个人生活习惯
  savePatientPersonalInfo: '/HealthPatientPersonalInfos/savePatientPersonalInfo',
  getPatientPersonalInfo: '/HealthPatientPersonalInfos/getPatientPersonalInfo',
  // 客户关联的预约信息
  savePatientAppointInfo: '/HealthPatientAppointments/savePatientAppointInfo',
  getPatientAppointInfo: '/HealthPatientAppointments/getPatientAppointInfo',
  // 客户关联的疾病和用药信息
  savePatientMedicalInfo: '/HealthPatientMedicalInfos/savePatientMedicalInfo',
  getPatientMedicalInfo: '/HealthPatientMedicalInfos/getPatientMedicalInfo',
  // 客户检测指标信息
  savePatientMeasureInfo: '/HealthPatientMeasureInfos/savePatientMeasureInfo',
  savePatientBodyInfo: '/HealthPatientMeasureInfos/savePatientBodyInfo',
  changeMeasureMonitorStatus: '/HealthPatientMeasureInfos/changeMeasureMonitorStatus',
  getPatientNewestBodyInfo: '/HealthPatientMeasureInfos/getPatientNewestBodyInfo',
  getPatientAllMeasureInfo: '/HealthPatientMeasureInfos/getPatientAllMeasureInfo',
  getPatientAllMeasureInfoHistory: '/HealthPatientMeasureInfos/getPatientAllMeasureInfoHistory',
  getPatientBodyInfoByServiceRecord: '/HealthPatientMeasureInfos/getPatientBodyInfoByServiceRecord',
  aiReadFileData: '/HealthPatientMeasureInfos/aiReadFileData',
  getAiConversationData: '/HealthPatientMeasureInfos/getAiConversationData',
  // 客户日常餐饮日志
  savePatientFoodDiaryInfo: '/HealthPatientFoodDiaryInfos/savePatientFoodDiaryInfo',
  // 客户活动情况日志
  savePatientActivityInfo: '/HealthPatientActivityInfos/savePatientActivityInfo',
  // 客户目标日志
  savePatientGoalInfo: '/HealthPatientGoalInfos/savePatientGoalInfo',
  // 营养处方保存
  savePatientNutritionInfo: '/HealthPrescriptionNutritions/savePatientNutritionInfo',
  getPatientNutritionHisList: '/HealthPrescriptionNutritions/getPatientNutritionHisList',
  getPatientNutritionInfo: '/HealthPrescriptionNutritions/getPatientNutritionInfo',
  removePatientPrescriptionSport: '/HealthPrescriptionNutritions/removePatientPrescriptionSport',
  // 客户回访记录
  getPatientFollowupRecord: '/HealthFollowupRecords/getPatientFollowupRecord',
  // 查找最近生日的客户
  getPatientByBirthday: '/HisPatients/getPatientByBirthday',
}

export function getPatientInfo (data) {
  return request({
    url: apiUrls.getPatientInfo,
    method: 'post',
    data
  })
}

export function selectListItemTemplateDef (parameter) {
  return request({
    url: apiUrls.selectListItemTemplateDef,
    method: 'get',
    params: parameter
  })
}

export function selectListPhyCycleDef (parameter) {
  return request({
    url: apiUrls.selectListPhyCycleDef,
    method: 'get',
    params: parameter
  })
}

export function listHisPatient (parameter) {
  return request({
    url: apiUrls.listHisPatient,
    method: 'get',
    params: parameter
  })
}

export function addPatientInfo (data) {
  return request({
    url: apiUrls.addPatientInfo,
    method: 'post',
    data
  })
}

export function updatePatientInfo (data) {
  return request({
    url: apiUrls.updatePatientInfo,
    method: 'post',
    data
  })
}

export function getHisPatientSelectList (parameter) {
  return request({
    url: apiUrls.getHisPatientSelectList,
    method: 'get',
    params: parameter
  })
}

export function listHisPlatformPatient (parameter) {
  return request({
    url: apiUrls.listHisPlatformPatient,
    method: 'get',
    params: parameter
  })
}

export function selectTreePhyCycleDef (parameter) {
  return request({
    url: apiUrls.selectTreePhyCycleDef,
    method: 'get',
    params: parameter
  })
}

export function savePatientDietaryInfo (data) {
  return request({
    url: apiUrls.savePatientDietaryInfo,
    method: 'post',
    data
  })
}

export function getPatientDietaryInfo (parameter) {
  return request({
    url: apiUrls.getPatientDietaryInfo,
    method: 'get',
    params: parameter
  })
}

export function savePatientPersonalInfo (data) {
  return request({
    url: apiUrls.savePatientPersonalInfo,
    method: 'post',
    data
  })
}

export function getPatientPersonalInfo (parameter) {
  return request({
    url: apiUrls.getPatientPersonalInfo,
    method: 'get',
    params: parameter
  })
}

export function savePatientAppointInfo (data) {
  return request({
    url: apiUrls.savePatientAppointInfo,
    method: 'post',
    data
  })
}

export function getPatientAppointInfo (parameter) {
  return request({
    url: apiUrls.getPatientAppointInfo,
    method: 'get',
    params: parameter
  })
}

export function savePatientMedicalInfo (data) {
  return request({
    url: apiUrls.savePatientMedicalInfo,
    method: 'post',
    data
  })
}

export function getPatientMedicalInfo (parameter) {
  return request({
    url: apiUrls.getPatientMedicalInfo,
    method: 'get',
    params: parameter
  })
}

export function savePatientMeasureInfo (data) {
  return request({
    url: apiUrls.savePatientMeasureInfo,
    method: 'post',
    data
  })
}

export function savePatientBodyInfo (data) {
  return request({
    url: apiUrls.savePatientBodyInfo,
    method: 'post',
    data
  })
}

export function changeMeasureMonitorStatus (data) {
  return request({
    url: apiUrls.changeMeasureMonitorStatus,
    method: 'post',
    data
  })
}

export function getPatientNewestBodyInfo (parameter) {
  return request({
    url: apiUrls.getPatientNewestBodyInfo,
    method: 'get',
    params: parameter
  })
}

export function getPatientAllMeasureInfo (parameter) {
  return request({
    url: apiUrls.getPatientAllMeasureInfo,
    method: 'get',
    params: parameter
  })
}

export function getPatientAllMeasureInfoHistory (parameter) {
  return request({
    url: apiUrls.getPatientAllMeasureInfoHistory,
    method: 'get',
    params: parameter
  })
}

export function getPatientBodyInfoByServiceRecord (parameter) {
  return request({
    url: apiUrls.getPatientBodyInfoByServiceRecord,
    method: 'get',
    params: parameter
  })
}

export function aiReadFileData (data) {
  return request({
    url: apiUrls.aiReadFileData,
    method: 'post',
    data
  })
}

export function getAiConversationData (data) {
  return request({
    url: apiUrls.getAiConversationData,
    method: 'post',
    data
  })
}

export function savePatientFoodDiaryInfo (data) {
  return request({
    url: apiUrls.savePatientFoodDiaryInfo,
    method: 'post',
    data
  })
}

export function savePatientActivityInfo (data) {
  return request({
    url: apiUrls.savePatientActivityInfo,
    method: 'post',
    data
  })
}

export function savePatientGoalInfo (data) {
  return request({
    url: apiUrls.savePatientGoalInfo,
    method: 'post',
    data
  })
}

export function savePatientNutritionInfo (data) {
  return request({
    url: apiUrls.savePatientNutritionInfo,
    method: 'post',
    data
  })
}

export function getPatientNutritionHisList (parameter) {
  return request({
    url: apiUrls.getPatientNutritionHisList,
    method: 'get',
    params: parameter
  })
}

export function getPatientNutritionInfo (parameter) {
  return request({
    url: apiUrls.getPatientNutritionInfo,
    method: 'get',
    params: parameter
  })
}

export function getPatientFollowupRecord (parameter) {
  return request({
    url: apiUrls.getPatientFollowupRecord,
    method: 'get',
    params: parameter
  })
}

export function removePatientPrescriptionSport (data) {
  return request({
    url: apiUrls.removePatientPrescriptionSport,
    method: 'post',
    data
  })
}

export function getPatientByBirthday (data) {
  return request({
    url: apiUrls.getPatientByBirthday,
    method: 'post',
    data
  })
}
